<script setup>
import {
  computed, reactive, ref, watch,
} from 'vue';
import ValidationInput from '../ValidationInput/ValidationInput.vue';
import useFormValidationStore from '../../store/formValidationStore';
import countries from '../../config/countriesExport.json';
import useGlobalStore from '../../store/globalStore';
import RenderString from '../RenderString/RenderString.vue';
import { buildTemplate } from '../../modules/helperFunctions';

/* Store */
const formValidationStore = useFormValidationStore();
const globalStore = useGlobalStore();
const allowedCountries = globalStore.getAllowedCountries;
const defaultCountrySelect = globalStore.getDefaultCountrySelect;
const isPhoneRegistration = globalStore.getPhoneRegistrationRequired;
/* Props */
// eslint-disable-next-line no-unused-vars
const props = defineProps({
  phoneError: {
    type: Boolean,
    required: true,
  },
  required: {
    type: Boolean,
    default: false,
  },
});

/* Emits */
const emit = defineEmits(['update:phoneNumberInput', 'validate-input', 'update:phoneNumberValidation']);
const nameInputRef = ref(null);
/* State */
const state = reactive({
  phoneCode: '',
  phoneNumberInput: '',
  dropdownActive: false,
  triggerPhoneNumberValidation: false,
});

const phoneNumberValidation = [
  formValidationStore.getIsRequired,
  formValidationStore.getIsPhone,
  isPhoneRegistration ? formValidationStore.getIsCNPhone : () => {},
];
/* Computed properties */
/* pre-selected countries based on allowed countries */
const availableCountries = computed(() => (isPhoneRegistration && allowedCountries.length > 0
  ? countries.filter((country) => allowedCountries.includes(country.locale.country)).map((country) => country)
  : countries));
/* Determine if the dropdown should be disabled */
const dropdownDisabled = computed(() => availableCountries.value.length === 1);
/*
always default to the first country in the list if the default country is not in the list
 */
const selectedCountry = (availableCountries.value.length > 0
  ? availableCountries.value : countries).find((country) => (country.locale.country === defaultCountrySelect
)) || (availableCountries.value.length > 0 ? availableCountries.value[0] : countries[0]);
/* initial state for selected phone code */
state.phoneCode = selectedCountry.phoneCode.replace(/\D/g, '');

/* Default to locale country  */
const defaultSelectedCountry = ref(selectedCountry);
const selectedPhoneCode = ref(defaultSelectedCountry.value.phoneCode);

// // Initially, it holds the selected value.
const tempSelection = ref(selectedPhoneCode.value);

/* Methods */
const handleBlur = () => {
  state.dropdownActive = false;
  if (!tempSelection.value) {
    tempSelection.value = selectedPhoneCode.value;
  }
};

const onFocus = (event) => {
  // If the focus is not mouse-initiated, event.detail will be 0.
  // We choose not to clear tempSelection in that case.
  if (event.detail > 0) {
    // Already cleared via onMouseDown.
    // (Optionally, you can clear here if needed.)
  }
};

const onMouseDown = () => {
  state.dropdownActive = true;
  tempSelection.value = '';
};

const triggerValidation = () => {
  if (nameInputRef.value) {
    nameInputRef.value.validate();
  }
};

const applyValidationRules = () => {
  const cnPhoneRule = formValidationStore.getIsCNPhone;
  const ruleIndex = phoneNumberValidation.indexOf(cnPhoneRule);

  if (state.phoneCode === '86') {
    if (ruleIndex === -1) {
      phoneNumberValidation.push(cnPhoneRule);
    }
  } else if (ruleIndex !== -1) {
    phoneNumberValidation.splice(ruleIndex, 1);
  }
};

const updateSelectedCountry = (event) => {
  const selectedCode = event.target.value;
  const country = availableCountries.value.find((c) => c.phoneCode === selectedCode);

  if (country.phoneCode === selectedPhoneCode.value) {
    state.dropdownActive = false;
    event.target.blur();
    return;
  }

  if (country) {
    defaultSelectedCountry.value = country;
    selectedPhoneCode.value = country.phoneCode;
    tempSelection.value = country.phoneCode;
    state.phoneCode = country.phoneCode.replace(/\D/g, '');
  }

  applyValidationRules();
  triggerValidation(nameInputRef);
  event.target.blur();
};
const handleValidateInput = async (name, invalid) => {
  emit('validate-input', name, invalid);
};

watch(
  () => [state.phoneNumberInput, state.phoneCode], // Watch both values
  ([newPhoneNumber, newPhoneCode]) => {
    const fullPhoneNumber = `${newPhoneCode}${newPhoneNumber}`; // Concatenate code + number

    // Emit only phoneNumberInput, but with full number
    emit('update:phoneNumberInput', fullPhoneNumber);
  },
);

/* Translated duplicate email error */
const phoneErrorString = buildTemplate(
  globalStore.translations.createAccPhoneNumberExist,
  ['<router-link :to="{ name: \'SignIn\' }">', '</router-link>'],
);
</script>

<template>
  <fieldset
    v-if="isPhoneRegistration"
    id="label-phone-registration"
    class="create-form__input"
  >
    <span class="g__dropdown__label__text">
      {{ globalStore.translations?.generalTextPhoneNumber }}
      <span
        v-if="isPhoneRegistration"
        class="g__dropdown__required"
      >*</span>
    </span>
    <div class="input-group__phone-registration">
      <g-drop-down
        class="validation-form__input__phone-registration-dropdown"
        :label="globalStore.translations?.generalTextPhoneNumber"
        hide-label="true"
      >
        <select
          id="country-region"
          v-model="tempSelection"
          autocomplete="tel-country-code"
          :disabled="dropdownDisabled"
          @focus="onFocus($event)"
          @mousedown="onMouseDown"
          @change="updateSelectedCountry($event)"
          @blur="handleBlur"
        >
          <option
            v-if="!state.dropdownActive"
            :value="selectedPhoneCode"
            selected
          >
            {{ state.dropdownActive ? '' : selectedPhoneCode }}
          </option>
          <option
            v-for="country in availableCountries"
            :key="country.locale.country"
            :value="country.phoneCode"
          >
            {{ `${country.label} (${country.phoneCode})` }}
          </option>
        </select>
      </g-drop-down>
      <Validation-Input
        id="phone-number"
        ref="nameInputRef"
        v-model.trim="state.phoneNumberInput"
        class="create-form__input"
        :validation-rules="phoneNumberValidation"
        :label-text="globalStore.translations.generalTextPhoneNumber"
        :required="required"
        hide-label="true"
        :phone-error="phoneError"
        :trigger-validation="state.triggerPhoneNumberValidation"
        name="phoneNumber"
        type="tel"
        @validate-input="handleValidateInput"
      />
    </div>
    <g-error
      v-if="phoneError"
      class="validation-input__error"
    >
      <Render-String :content="phoneErrorString" />
    </g-error>
  </fieldset>
</template>

<style lang="scss" scoped>
.input-group {
  &__phone-registration {
    display: flex;
    align-items: flex-start;
    gap: 0.5rem;
    width: 100%;
    margin-bottom: -0.5rem;

    .g__dropdown {
      width: auto;

      &__arrow {
        right: 0.5rem;
      }

      select {
        padding: 0.875em 0 0.875em 0.875em;
      }
    }

    :deep() .g__dropdown__wrapper {
      width: 5.5rem;
    }

    .validation-input {
      width: 100%;
    }
  }
}

.g__error {
  margin-bottom: 1rem;

  a {
    font-size: 0.8rem;
  }
}

#phone-number {
  width: 100%;
}
</style>
