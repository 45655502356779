<script setup>
/* Imports */
import {
  computed, reactive, ref, watch,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import useGlobalStore from '../../store/globalStore';
import useClientRegistriesStore from '../../store/clientRegistriesStore';
import useSigninStore from '../../store/signinStore';
import useMfaStore from '../../store/mfaStore';
import useFormValidationStore from '../../store/formValidationStore';
import useViewStore from '../../store/viewStore';
import PasswordInput from '../PasswordInput/PasswordInput.vue';
import PortalPrimaryHeader from '../../components/PortalPrimaryHeader/PortalPrimaryHeader.vue';
import ValidationInput from '../../components/ValidationInput/ValidationInput.vue';
import ValidationForm from '../../components/ValidationForm/ValidationForm.vue';
import PortalFooterCreateAccount from '../PortalFooterCreateAccount/PortalFooterCreateAccount.vue';
import CustomCaptcha, { verifyCaptcha, expiredCaptcha } from '../../components/Recaptcha/CustomCaptcha.vue';
import successHandler from '../../modules/successHandler';

/* Router */
const route = useRoute();
const router = useRouter();
/* Store */
const globalStore = useGlobalStore();
const clientRegistriesStore = useClientRegistriesStore();
const signInStore = useSigninStore();
const mfaStore = useMfaStore();
const formValidationStore = useFormValidationStore();
const viewStore = useViewStore();

/* Fields validation */
const emailValidation = [formValidationStore.getIsRequired];
const passwordValidation = [formValidationStore.getIsRequired];
/* State */
const state = reactive({
  lockToEmailAddress: clientRegistriesStore.getLockToEmailAddress,
  emailInput: '',
  captchaToken: '',
  passwordInput: '',
  rememberMeInput: false,
  validation: {},
  triggerEmailValidation: false,
  triggerPasswordValidation: false,
  formLoading: false,
  continueAsGuest: clientRegistriesStore.getContinueAsGuest,
  isCaptchaEnable: window?.GarminAppBootstrap?.isCaptchaEnabled,
  captchaAlreadyPassed: false,
  continueAsGuestUrl: computed(() => {
    const { locale } = route.params;
    if (window?.GarminAppBootstrap?.buyUrl) {
      if (window.GarminAppBootstrap.buyUrl === 'https://buy.garmin.com.cn') {
        return 'https://buy.garmin.com.cn/webstore/checkout/step/2';
      }
      return `${window.GarminAppBootstrap.buyUrl}/${locale}/checkout`;
    }

    return `https://buygarmintest.garmin.com/${locale}/checkout`;
  }),
  generalHeaderWithDcName: 'generalHeaderSignIn',
});
/**
 * pre-populate email field if getLockToEmailAddress is true
 *  and trigger validation
 */
if (state.lockToEmailAddress) {
  state.emailInput = state.lockToEmailAddress;
  state.triggerEmailValidation = true;
}

/**
 * pre-populate email field after forgot password
 * request and trigger validation
 */
const forgotPasswordEmail = ref(localStorage.getItem('forgotPasswordEmail'));
if (forgotPasswordEmail.value) {
  state.emailInput = forgotPasswordEmail.value;
  state.triggerEmailValidation = true;
}

const validateInput = (name, invalid) => {
  state.validation[name] = invalid;
  if (state.captchaAlreadyPassed) {
    delete state.validation.captcha;
  }
};

/* Handle submit of the form */
const handleSubmit = async () => {
  state.formLoading = true;
  if (state.captchaAlreadyPassed) {
    delete state.validation.captcha;
  }
  const signInRes = await signInStore.setSignIn({
    username: state.emailInput,
    password: state.passwordInput,
    rememberMe: state.rememberMeInput,
    captchaToken: state.captchaToken,
  });

  const {
    responseStatus,
    captchaAlreadyPassed,
  } = signInRes;
  const { type } = responseStatus || {};

  // if user already passed captcha  do not show it again until refresh
  if (captchaAlreadyPassed) {
    state.captchaAlreadyPassed = captchaAlreadyPassed;
    state.isCaptchaEnable = !captchaAlreadyPassed;
    const additionalRequiredElements = document.querySelectorAll('.additional-required');
    if (additionalRequiredElements.length > 0) {
      additionalRequiredElements.item(0).classList.remove('.additional-required');
      console.log(additionalRequiredElements);
    }
  }

  /* reset captcha after submit */
  if (!captchaAlreadyPassed && window.grecaptcha && window.grecaptcha.enterprise) {
    window.grecaptcha.enterprise.reset();
  }
  /* Redirect to change password if required */
  if (type === 'PASSWORD_CHANGE_REQUIRED') {
    viewStore.setCurrentView('changePassword');
    return;
  }

  if (type === 'RECONSENT_REQUIRED') {
    viewStore.setCurrentView('reConsentForm');

    return;
  }

  /* Redirect to Verify Phone if required */
  if (type === 'PHONE_VERIFICATION_REQUIRED') {
    viewStore.setCurrentView('verifyPhone');

    return;
  }

  /* Redirect to Verify Phone if required */
  if (type === 'PHONE_REGISTRATION_REQUIRED') {
    viewStore.setCurrentView('updatePhone');

    return;
  }

  if (type === 'CAPTCHA_REQUIRED') {
    state.captchaAlreadyPassed = captchaAlreadyPassed;
    state.isCaptchaEnable = !captchaAlreadyPassed;
    state.formLoading = false;
    state.validation.captcha = true;
    viewStore.setCurrentView('signIn');

    return;
  }

  /* Redirect to MFA if required */
  if (mfaStore.getIsMfaFlow) {
    router.push({ name: 'Mfa' });

    return;
  }

  if (type === 'SUCCESSFUL') {
    successHandler(signInRes);
    return;
  }

  state.formLoading = false;
};

const handleForgotPassword = (e) => {
  e.preventDefault();
  viewStore.setCurrentView('forgotPassword');
};

const generalHeaderWithDataCenterPrefix = state.generalHeaderWithDcName.concat(window?.GarminAppBootstrap?.dcName);

const computedPrimaryHeaderTitle = computed(() => (
  window?.GarminAppBootstrap?.dcName
    ? globalStore.translations?.[generalHeaderWithDataCenterPrefix]
    : globalStore.translations.generalHeaderSignInUS));

/* watch email and password value and trigger validation especially for password manager */
/* eslint-disable-next-line no-unused-vars */
watch(() => [state.emailInput, state.passwordInput], ([newEmail, newPass]) => {
  state.triggerEmailValidation = Boolean(newEmail);
  state.triggerPasswordValidation = Boolean(newPass);
});

</script>

<template>
  <div class="signin">
    <Portal-Primary-Header :title="computedPrimaryHeaderTitle" />
    <Validation-Form
      class="signin__form"
      :submit-form="handleSubmit"
      :submit-btn-text="globalStore.translations.generalButtonSignIn"
      :validation="state.validation"
      :loading="state.formLoading"
    >
      <fieldset class="signin__form__input">
        <Validation-Input
          id="email"
          v-model="state.emailInput"
          class="signin__form__input"
          :validation-rules="emailValidation"
          :label-text="globalStore.translations.generalTextEmailAddress"
          required
          :read-only="Boolean(state.lockToEmailAddress)"
          name="email"
          type="email"
          :trigger-validation="state.triggerEmailValidation"
          :validate-on-key-up="false"
          @validate-input="validateInput"
        />
      </fieldset>
      <fieldset class="signin__form__input password">
        <Password-Input
          id="password"
          v-model="state.passwordInput"
          class="signin__form__input"
          required
          :validation-rules="passwordValidation"
          :label-text="globalStore.translations.generalTextPassword"
          name="password"
          :trigger-validation="state.triggerPasswordValidation"
          :validate-on-key-up="false"
          @validate-input="validateInput"
        />
      </fieldset>
      <div class="signin__form__input options">
        <fieldset class="signin__form__input--remember">
          <g-checkbox :label="globalStore.translations.generalTextRememberMe">
            <input
              v-model="state.rememberMeInput"
              name="remember"
              type="checkbox"
            >
          </g-checkbox>
        </fieldset>
        <fieldset class="signin__form__input--forgot">
          <button
            type="button"
            class="signin__form__input--forgot-btn"
            @click="handleForgotPassword"
          >
            {{ globalStore.translations.generalHeaderForgotPassword }}
          </button>
        </fieldset>
      </div>
      <CustomCaptcha
        v-if="state.isCaptchaEnable && !state.captchaAlreadyPassed"
        :view-name="viewStore.getCurrentView"
        :client-id="clientRegistriesStore.getClientId"
        @verify="verifyCaptcha(state)"
        @expired="expiredCaptcha(state)"
      />
    </Validation-Form>

    <Portal-Footer-Create-Account />
    <p
      v-if="state.continueAsGuest"
      class="continue-as-guest"
    >
      <a
        id="continue-as-guest"
        :href="state.continueAsGuestUrl"
      >
        {{ globalStore.translations.generalLoginContinueAsGuest }}
      </a>
    </p>
  </div>
</template>

<style lang="scss">
.g__checkbox__label {
  // Override the default style from the Global Components
  // stylelint-disable-next-line declaration-no-important
  padding: 0.7em 0 0.7em 1.7em !important;
}

.signin__form {
  &__input {
    &.password {
      .validation-form__input {
        margin-bottom: 0.5em;
      }
    }

    &.options {
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1.5em;
    }

    &--forgot-btn {
      background: transparent;
      border: 0;
      color: var(--sso-text-link-textColor);
      cursor: pointer;
      font-family: var(--sso-text-body-fontStack);
      font-size: var(--sso-text-body-fontSize);
      font-weight: 400;
      text-decoration: underline;
    }
  }
}

.continue-as-guest {
  text-align: center;
  padding-top: 1em;
}
</style>
