<script setup>
/* Imports */
import { reactive } from 'vue';
import { useRouter } from 'vue-router';
import useGlobalStore from '../../store/globalStore';
import useMfaStore from '../../store/mfaStore';

import PortalPrimaryHeader from '../../components/PortalPrimaryHeader/PortalPrimaryHeader.vue';
import PortalCopyCon from '../../components/PortalCopyCon/PortalCopyCon.vue';
import PortalButton from '../../components/PortalButton/PortalButton.vue';
import PortalDisclaimer from '../PortalDisclaimer/PortalDisclaimer.vue';

import successHandler from '../../modules/successHandler';

/* Store */
const globalStore = useGlobalStore();
const mfaStore = useMfaStore();

/* Route */
const router = useRouter();

/* props */
const props = defineProps({
  pageHeader: {
    type: String,
    default: '',
  },
});

const state = reactive({
  btnLoading: false,
});

/* Handle done button */
const handleDone = async (e) => {
  e.preventDefault();

  state.btnLoading = true;
  const response = await mfaStore.confirmMfaSetup({}); // need to pass payload?

  const {
    responseStatus,
  } = response;
  const { type } = responseStatus;

  // Session Expired
  if (type === 'SESSION_EXPIRED') {
    router.push({ name: 'SignIn' });
    return;
  }

  if (type === 'SUCCESSFUL') {
    successHandler(response);
    return;
  }
  state.btnLoading = false;
};
</script>

<template>
  <div class="mfa-enabled">
    <Portal-Primary-Header
      :title="props.pageHeader"
    />
    <g-copy
      type="large"
    >
      {{ globalStore.translations.setupMfaConfirmationContentTitle }}
    </g-copy>
    <Portal-Copy-Con>
      <g-copy
        type="large"
      >
        {{ globalStore.translations.setupMfaConfirmationContent }}
      </g-copy>
      <Portal-Disclaimer />
    </Portal-Copy-Con>
    <PortalButton
      theme="ocean-blue"
      size="large"
      data-testid="done-btn"
      :loading="state.btnLoading"
      @click="handleDone"
    >
      {{ globalStore.translations.mfaButtonContinue }}
    </PortalButton>
  </div>
</template>
