import { defineStore } from 'pinia';
import merge from 'lodash/merge';
import { flattenObject } from '../modules/helperFunctions';

export const defaultVisualOptions = {
  global: {
    backgroundImages: [],
    mobileBackgroundImages: [],
    tabletBackgroundImages: [],
    header: {
      backgroundColor: '#ffffff',
      textColor: '#000000',
      bottomBorderColor: '#dcdcdc',
      logoAlignmentMobile: 'center',
      logoAlignmentDesktop: 'left',
    },
    displayFormHeaderGarminLogo: false,
    backgroundColor: '#ffffff',
    hidePageHeader: false,
    hidePageFooter: false,
    hidePrimaryHeader: false,
  },
  text: {
    h1: {
      fontStack: '"Oswald", Roboto Condensed, Roboto, Noto Sans TC, Noto Sans SC, Noto Sans JP, Noto Sans KR, Prompt, sans-serif',
      fontSize: '28px',
      textColor: '#000000',
      textTransform: 'uppercase',
    },
    h2: {
      fontStack: '"Oswald", Roboto Condensed, Roboto, Noto Sans TC, Noto Sans SC, Noto Sans JP, Noto Sans KR, Prompt, sans-serif',
      fontSize: '24px',
      textColor: '#000000',
      textTransform: 'none',
    },
    body: {
      fontStack: 'Roboto, Noto Sans TC, Noto Sans SC, Noto Sans JP, Noto Sans KR, Prompt, sans-serif',
      fontSize: '16px',
      textColor: '#000000',
    },
    link: {
      textColor: '#000000',
    },
  },
  button: {
    primary: {
      default: {
        padding: '0.625rem 1.5rem',
        textTransform: 'uppercase',
        backgroundColor: '#007bc1',
        borderColor: '#007bc1',
        textColor: '#FFFFFF',
        borderRadius: '0',
      },
      hover: {
        backgroundColor: '#FFFFFF',
        borderColor: '#007bc1',
        textColor: '#007bc1',
      },
      active: {
        backgroundColor: '#FFFFFF',
        borderColor: '#007bc1',
        textColor: '#007bc1',
      },
      visited: {
        backgroundColor: '#007bc1',
        borderColor: '#007bc1',
        textColor: '#FFFFFF',
      },
      disabled: {
        backgroundColor: '#d9d9d9',
        borderColor: '#d9d9d9',
        textColor: '#000000',
      },
    },
    secondary: {
      default: {
        padding: '0.625rem 1.5rem',
        textTransform: 'uppercase',
        backgroundColor: 'rgba(0,0,0,0)',
        textColor: '#000000',
      },
    },
  },
  input: {
    default: {
      backgroundColor: '#FFFFFF',
      borderColor: '#DCDCDC',
      borderRadius: '0',
      borderWidth: '1px',
    },
  },
};

export default defineStore('visualOptionsStore', {
  state: () => ({
    visualOptions: defaultVisualOptions,
  }),
  getters: {
    getVisualOptions: (state) => state.visualOptions,
    getBackgroundImagesLength: (state) => state.visualOptions.global?.backgroundImages?.length,
    getMobileBackgroundImagesLength: (state) => state.visualOptions.global?.mobileBackgroundImages?.length,
    getTabletBackgroundImagesLength: (state) => state.visualOptions.global?.tabletBackgroundImages?.length,
    getBackgroundImage: (state) => {
      if (state.getBackgroundImagesLength) {
        const images = state.visualOptions.global.backgroundImages;
        return `url('${state.getRandomImage(images)}')`;
      }
      return 'none';
    },
    getTabletBackgroundImage: (state) => {
      if (state.getTabletBackgroundImagesLength) {
        const images = state.visualOptions.global.tabletBackgroundImages;
        return `url('${state.getRandomImage(images)}')`;
      }
      return state.getBackgroundImage;
    },
    getMobileBackgroundImage: (state) => {
      if (state.getMobileBackgroundImagesLength) {
        const images = state.visualOptions.global.mobileBackgroundImages;
        return `url('${state.getRandomImage(images)}')`;
      }
      return state.getTabletBackgroundImage;
    },
    getRandomImage: () => (images) => images[Math.floor(Math.random() * images.length)],
    getBackgroundColor: (state) => state.visualOptions.global?.backgroundColor ?? '#ffffff',
    getHidePageHeader: (state) => state.visualOptions.global?.hidePageHeader ?? false,
    getHidePageFooter: (state) => state.visualOptions.global?.hidePageFooter ?? false,
    getHidePrimaryHeader: (state) => state.visualOptions.global?.hidePrimaryHeader ?? false,
    getStyles: (state) => state.visualOptions.styles,
  },
  actions: {
    setCustomVisualOptions(payload) {
      /**
       * Merges custom visual options with default values,
       * overriding the default values with custom ones that have been passed in
       */
      this.visualOptions = merge(this.visualOptions, payload);
      this.convertVisOptionsToCSSCustomProperties();
    },
    convertVisOptionsToCSSCustomProperties() {
      const flattendVisualOptions = flattenObject({ ...this.getVisualOptions });
      /**
       * Remove properties that are not needed as CSS custom properties
       */
      delete flattendVisualOptions.global_displayFormHeaderGarminLogo;

      Object.keys(flattendVisualOptions).forEach((key) => {
        if (
          key.startsWith('global_backgroundImages')
            || key.startsWith('global_mobileBackgroundImages')
            || key.startsWith('global_tabletBackgroundImages')
        ) {
          delete flattendVisualOptions[key];
        }
      });

      /* Set visual options as CSS custom properties */
      Object.keys(flattendVisualOptions).forEach((value) => {
        const formattedValue = value.replaceAll('_', '-');
        document.documentElement.style.setProperty(
          `--sso-${formattedValue}`,
          flattendVisualOptions[value],
        );
      });

      document.documentElement.style.setProperty('--sso-global-backgroundColor', this.getBackgroundColor);
      document.documentElement.style.setProperty('--sso-global-backgroundImage', this.getBackgroundImage);
      document.documentElement.style.setProperty('--sso-global-mobileBackgroundImage', this.getMobileBackgroundImage);
      document.documentElement.style.setProperty('--sso-global-tabletBackgroundImage', this.getTabletBackgroundImage);
    },
  },
});
