<script setup>
/* Imports */
import { computed, reactive } from 'vue';

import PortalContainer from '../PortalContainer/PortalContainer.vue';
import useGlobalStore from '../../store/globalStore';
import useClientRegistriesStore from '../../store/clientRegistriesStore';
import useVisualOptionsStore from '../../store/visualOptionsStore';
import useConsentStore from '../../store/consentStore';

/* Store */
const globalStore = useGlobalStore();
const visualOptionsStore = useVisualOptionsStore();
const clientRegistriesStore = useClientRegistriesStore();
const consentStore = useConsentStore();

// set initial store data
globalStore.setTranslations();
globalStore.setLocale();
globalStore.setService();
globalStore.setPrivacyUrl();
globalStore.setSecurityUrl();
globalStore.setTermsOfUseUrl();
globalStore.setAccountManagementUrl();
globalStore.setIsChinaLocation();
globalStore.setAccountVerificationEnabled();
globalStore.setPhoneRegistrationRequired();
globalStore.setLoginIdentifierEnabled();
globalStore.setAllowedCountries();
globalStore.setPrimaryType();
clientRegistriesStore.setClientRegistriesData();
consentStore.setConsentStoreData();
consentStore.setDcRequirePrivacyConsent();
consentStore.setIsCloudflareHeaderNeedsConsent();
consentStore.setPrivacyConsentEnabledCountries();

/* State */
const state = reactive({
  customVisualOptions: computed(() => clientRegistriesStore.getRegistryVisualOptions),
});

// Set visual options based on client registry visual options
if (clientRegistriesStore?.getRegistryVisualOptions) {
  visualOptionsStore.setCustomVisualOptions(state.customVisualOptions);
} else {
  // Fallback to default visual options if none are set in client registry
  visualOptionsStore.setCustomVisualOptions();
}

</script>

<template>
  <Portal-Container
    :loading="globalStore.getPortalLoading"
  >
    <router-view />
  </Portal-Container>
</template>

<style lang="scss">
html {
  /* mobile viewport bug fix */
  /* stylelint-disable value-no-vendor-prefix */
  min-height: -webkit-fill-available;
}

body {
  background-image: var(--sso-global-mobileBackgroundImage);
  margin: 0;
  font-family: var(--sso-text-body-fontStack);
  font-size: var(--sso-text-body-fontSize);
  background-attachment: fixed;
  background-color: var(--sso-global-backgroundColor);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  color: var(--sso-text-body-textColor);
  /* stylelint-disable declaration-block-no-duplicate-properties */
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;

  @include breakpoint('xs') {
    background-image: var(--sso-global-mobileBackgroundImage);
  }

  @include breakpoint('sm') {
    background-image: var(--sso-global-tabletBackgroundImage);
  }

  @include breakpoint('lg') {
    background-image: var(--sso-global-backgroundImage);
  }
}
/* Component Theme Overrides */
// font override for all global components

[class ^= 'g__'] {
  font-family: var(--sso-text-body-fontStack);
}

.g__copy {
  padding-bottom: 1px; // fix x-axis scrollbar showing for Opens Sans
}

a {
  color: var(--sso-text-link-textColor);
  text-decoration: underline;
}

h1,
.g__heading h1 {
  color: var(--sso-text-h1-textColor);
  font-family: var(--sso-text-h1-fontStack);
  font-size: var(--sso-text-h1-fontSize);
  text-transform: var(--sso-text-h1-textTransform);
}

h2,
.g__heading h2 {
  color: var(--sso-text-h2-textColor);
  font-family: var(--sso-text-h2-fontStack);
  font-size: var(--sso-text-h2-fontSize);
  text-transform: var(--sso-text-h2-textTransform);
}

p {
  margin: 0;
}

fieldset {
  border: none;
  padding: 0;
  margin-left: 0;
  margin-right: 0;
}

// Primary Button

.g__button--contained--ocean-blue {
  padding: var(--sso-button-primary-default-padding);
  text-transform: var(--sso-button-primary-default-textTransform);
  background-color: var(--sso-button-primary-default-backgroundColor);
  border-color: var(--sso-button-primary-default-borderColor);
  color: var(--sso-button-primary-default-textColor);
  border-radius: var(--sso-button-primary-default-borderRadius);

  &:hover {
    background-color: var(--sso-button-primary-hover-backgroundColor);
    border-color: var(--sso-button-primary-hover-borderColor);
    color: var(--sso-button-primary-hover-textColor);
  }

  &:active {
    background-color: var(--sso-button-primary-active-backgroundColor);
    border-color: var(--sso-button-primary-active-borderColor);
    color: var(--sso-button-primary-active-textColor);
  }

  &:visited {
    background-color: var(--sso-button-primary-visited-backgroundColor);
    border-color: var(--sso-button-primary-visited-borderColor);
    color: var(--sso-button-primary-visited-textColor);
  }

  &:disabled {
    background-color: var(--sso-button-primary-disabled-backgroundColor);
    border-color: var(--sso-button-primary-disabled-borderColor);
    color: var(--sso-button-primary-disabled-textColor);
  }
}

// Secondary Button

.g__button--flat {
  background-color: var(--sso-button-secondary-default-backgroundColor);
  border-color: var(--sso-button-secondary-default-borderColor);
  color: var(--sso-button-secondary-default-textColor);
  padding: var(--sso-button-secondary-default-padding);
  text-transform: var(--sso-button-secondary-default-textTransform);
}

// Inputs

.g__input input,
.g__input select {
  // Will use 1rem unless it is smaller than 16px. iOS will
  // zoom inputs if font-size is less than 16px
  font-size: max(16px, 1rem);
}

.g__input input:not(input:disabled),
.g__dropdown select,
.g__input--with-floating-label .g__input__wrapper {
  background-color: var(--sso-input-default-backgroundColor);
}

.g__input:not(.g__input--with-floating-label) input,
.g__dropdown select,
.g__input--with-floating-label .g__input__wrapper {
  border-radius: var(--sso-input-default-borderRadius);
  border-width: var(--sso-input-default-borderWidth);
}

.g__input:not(.g__input--error) input,
.g__dropdown:not(.g__dropdown--error) select,
.g__input--with-floating-label .g__input__wrapper {
  border-color: var(--sso-input-default-borderColor);
}

.g__input--with-floating-label .g__input__wrapper input {
  border-radius: var(--sso-input-default-borderRadius);
}

// Input Label

.g__input .g__input__label .g__input__label__text {
  font-size: 1em;
}

// Input Floating Label

.g__input.g__input--with-floating-label button {
  font-size: 1em;
  text-decoration: underline;
}

// Dropdown Label

.g__dropdown .g__dropdown__label .g__dropdown__label__text {
  font-size: 1em;
}

.g__checkbox__label,
.g__radio__label {
  padding: 0.6em 0 0.6em 1.85em;
  font-weight: 400;
}
</style>
