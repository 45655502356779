const platform = window?.GarminAppBootstrap?.platform;

let directory = 'portal';
if (platform && platform.toLowerCase() === 'mobile') {
  directory = 'mobile';
}

const subdirectory = 'sso';
const apiDirectory = 'api';

export default {
  directory,
  subdirectory,
  baseUrl: `/${directory}/${subdirectory}/:locale`,
  apiUrl: `/${directory}/${apiDirectory}`,
};
