import { createRouter, createWebHistory } from 'vue-router';
import useGlobalStore from '../store/globalStore';
import useClientRegistriesStore from '../store/clientRegistriesStore';
import baseUrls from './config/baseUrls';

import SignIn from '../views/SignIn/SignIn.vue';
import CreateAccount from '../views/CreateAccount/CreateAccount.vue';
import DateOfBirth from '../views/DateOfBirth/DateOfBirth.vue';
import Mfa from '../views/Mfa/Mfa.vue';
import NotFound from '../views/NotFound/NotFound.vue';
import Logout from '../views/Logout/Logout.vue';

/* Route strings */
const { baseUrl } = baseUrls;

const routes = [
  {
    name: 'SignIn',
    path: `${baseUrl}/sign-in`,
    component: SignIn,
  },
  {
    name: 'DateOfBirth',
    path: `${baseUrl}/date-of-birth`,
    component: DateOfBirth,
  },
  {
    name: 'CreateAccount',
    path: `${baseUrl}/create-account`,
    component: CreateAccount,
  },
  {
    name: 'Mfa',
    path: `${baseUrl}/mfa`,
    component: Mfa,
  },
  {
    name: 'Logout',
    path: `${baseUrl}/logout`,
    component: Logout,
  },
  /* https://router.vuejs.org/guide/essentials/dynamic-matching.html#catch-all-404-not-found-route */
  {
    name: 'NotFound',
    path: `${baseUrl}/:pathMatch(.*)*`,
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const hasQueryParams = (route) => !!Object.keys(route.query).length;
  const globalStore = useGlobalStore();
  const clientRegistriesStore = useClientRegistriesStore();
  const bsLocale = globalStore.getLocale;
  const urlLocale = to.params.locale;
  const bsClientId = clientRegistriesStore.getClientId;
  const urlClientId = to.query.clientId;
  const urlService = to.query.service;
  const bsService = window.GarminAppBootstrap?.service;

  /*
    if the route we're coming from has query params
    and the route we're headed to does not
    we need to pass along the from route query params
    so all routes maintain the original query params
    which load the correct SSO Portal settings
  */
  const passQueries = hasQueryParams(from) && !hasQueryParams(to);

  /*
    If the locale in the url doesn't match the locale in the bootstrap,
    it's unsupported and needs to be set to the bootstrap locale
  */
  const changeLocale = urlLocale !== bsLocale;

  /*
    if the clientId in the url doesn't match the clientId in the bootstrap, it's not a supported clientId,
    so change the clientId in the url to match the supported clientId in the bootstrap
  */
  const changeClientId = urlClientId !== bsClientId;

  /*
    if the service in the url doesn't match the service in the bootstrap, it's either invalid or not present,
    so change the service in the url to match the supported service in the bootstrap
   */
  const changeService = urlService !== bsService;

  if (passQueries || changeLocale || changeClientId || changeService) {
    const query = passQueries
      // pass from route queries to maintain them on all routes
      ? from.query
      // if we're not passing the from route queries, we can keep the queries of the route we're headed to
      : to.query;

    const locale = changeLocale
      // the url locale is not supported, so change it to the supported bootstrap locale
      ? bsLocale
      // if we're not changing the locale, we still need to pass a locale to the route
      : urlLocale;

    const clientId = changeClientId
      // the url clientId is not supported, so change it to the supported bootstrap clientId
      ? bsClientId
      // if the url clientId is supported and doesn't need to be changed, we still need to pass a clientId to the route
      : urlClientId;

    const service = changeService
      ? bsService
      : urlService;

    const payload = {
      name: to.name,
      query: {
        ...query,
        clientId,
        service,
      },
      params: { locale },
    };

    next(payload);
  } else {
    next();
  }
});

export default router;
