/**
 * Helper function to perform a POST request to the specified path with the SAMLResponse as a form parameter.
 * @param path {string} - The path to perform the POST request to.
 * @param samlResponse {string} - The base64 encoded SAML response to send as a form parameter.
 * @param relayState {string} - The relay state to send as a form parameter.
 */
function postForm(path, samlResponse, relayState) {
  const method = 'post';
  const form = document.createElement('form');
  form.setAttribute('method', method);
  form.setAttribute('action', path);
  let hiddenField = document.createElement('input');
  hiddenField.setAttribute('type', 'hidden');
  hiddenField.setAttribute('name', 'SAMLResponse');
  hiddenField.setAttribute('value', samlResponse);
  form.appendChild(hiddenField);
  hiddenField = document.createElement('input');
  hiddenField.setAttribute('type', 'hidden');
  hiddenField.setAttribute('name', 'RelayState');
  hiddenField.setAttribute('value', relayState);
  form.appendChild(hiddenField);
  document.body.appendChild(form);
  form.submit();
}

/**
 * Helper function to build a URL that may or may not have existing parameters and values and append with
 * an additional map of query parameters.
 * @param serviceUrl {string} - the base url to decode and add addtional query parameters to.
 * @param queryParams {Map<string, string>} - a map of query parameters to add to the serviceUrl.
 */
function buildUrl(serviceUrl, queryParams) {
  const decodedServiceUrl = decodeURIComponent(serviceUrl);
  let computedServiceURL = '';
  if (serviceUrl.includes('?')) {
    const splitURL = serviceUrl.split('?');
    const serviceURLPath = decodeURIComponent(splitURL[0]);
    const serviceURLParams = splitURL[1];
    if (serviceURLParams != null) {
      computedServiceURL = `${serviceURLPath}?${(serviceURLParams)}`;
    }
  } else {
    computedServiceURL = decodedServiceUrl;
  }

  if (queryParams) {
    const queryString = Object.keys(queryParams)
      .map((key) => `${key}=${queryParams[key]}`)
      .join('&');
    computedServiceURL += computedServiceURL.includes('?') ? `&${queryString}` : `?${queryString}`;
  }
  return computedServiceURL;
}

/**
 * Handles a SAML HTTP-POST protocol binding response.  This function will perform a POST to the serviceURL with the
 * SAML response as a form parameter.
 * @param serviceURL {string} - The URL to perform the SAML postback.
 * @param samlResponse {string} - The BASE64 encoded SAML response
 * @param relayState {string} - The relay state to send as a form parameter.
 */
function samlPostBack(serviceURL, samlResponse, relayState) {
  const url = buildUrl(serviceURL, {});
  postForm(`${url}`, `${samlResponse}`, `${relayState}`);
}

/**
 * Handles a SAML HTTP-Redirect protocol binding response.  This function will redirect the user to the serviceURL with
 * an addtional SAMLResposne query parameter and the entire encoded SAML response.
 * @param serviceUrl {string} - The base service URL to redirect the user to.
 * @param samlResponse {string} - The BASE64 encoded SAML response to attach to redirect URl.
 * @param relayState {string} - The relay state to send as an additional query parameter.
 */
function samlRedirect(serviceUrl, samlResponse, relayState) {
  const url = buildUrl(serviceUrl, { SAMLResponse: samlResponse, RelayState: relayState });
  window.location.href = url;
}

/**
 * Handles a CAS service redirect.  This function will redirect the user to the serviceURL with the serviceTicket added
 * as a query param.
 * @param serviceUrl {string} - The base service URL to redirect the user to.
 * @param serviceTicketId {string }- The service ticket id.  EG: ST-12345-sso
 */
function casRedirect(serviceUrl, serviceTicketId) {
  const url = buildUrl(serviceUrl, { ticket: serviceTicketId });
  window.location.href = url;
}

export default (response) => {
  const {
    authType,
    serviceURL,
    serviceTicketId,
    responseStatus,
    samlResponse,
  } = response;
  const { type } = responseStatus;

  if (type !== 'SUCCESSFUL') {
    return;
  }

  if (authType === 'SAML_POST') {
    samlPostBack(serviceURL, samlResponse, window.GarminAppBootstrap.relayState);
    return;
  }

  if (authType === 'SAML_REDIRECT') {
    samlRedirect(serviceURL, serviceTicketId, window.GarminAppBootstrap.relayState);
    return;
  }

  // Use the default redirect method if the authType is not SAML or SAML_REDIRECT (e.g. CAS)
  casRedirect(serviceURL, serviceTicketId);
};
