<script setup>
/* Imports */
import { computed, reactive } from 'vue';
import { useRouter } from 'vue-router';
import { buildTemplate } from '../../modules/helperFunctions';
import useGlobalStore from '../../store/globalStore';
import useViewStore from '../../store/viewStore';
import useDateOfBirthStore from '../../store/dateOfBirthStore';

import PortalPrimaryHeader from '../../components/PortalPrimaryHeader/PortalPrimaryHeader.vue';
import PortalCopyCon from '../../components/PortalCopyCon/PortalCopyCon.vue';
import PortalButton from '../../components/PortalButton/PortalButton.vue';

/* Store */
const globalStore = useGlobalStore();
const viewStore = useViewStore();
const dateOfBirthStore = useDateOfBirthStore();

/* Route */
const router = useRouter();

/* State */
const state = reactive({
  age: dateOfBirthStore.getAge,
  btnLoading: false,
});

const confirmAgeMessage = computed(() => buildTemplate(
  globalStore.translations.dobEntryConfirmAgeText,
  [state.age],
));

/* Handle submit the form */
const handleConfirm = async (e) => {
  e.preventDefault();
  state.btnLoading = true;
  router.push({ name: 'CreateAccount' });
  state.btnLoading = false;
};

const handleBack = (e) => {
  e.preventDefault();
  viewStore.setCurrentView('dateOfBirth');
};

</script>

<template>
  <div class="create">
    <Portal-Primary-Header
      :title="globalStore.translations.dobEntryConfirmAgeHeading"
    />
    <Portal-Copy-Con>
      <g-copy
        type="large"
      >
        {{ confirmAgeMessage }}
      </g-copy>
    </Portal-Copy-Con>
    <div>
      <PortalButton
        theme="ocean-blue"
        size="large"
        data-testid="next-btn"
        @click="handleConfirm"
      >
        {{ globalStore.translations.generalButtonConfirm }}
      </PortalButton>
      <PortalButton
        size="large"
        flat
        data-testid="back-btn"
        @click="handleBack"
      >
        {{ globalStore.translations.generalButtonGoBack }}
      </PortalButton>
    </div>
  </div>
</template>
