<script setup>
/* Imports */
import useGlobalStore from '../../store/globalStore';
import useClientRegistriesStore from '../../store/clientRegistriesStore';

import PortalFooter from '../../components/PortalFooter/PortalFooter.vue';

/* Store */
const globalStore = useGlobalStore();
const clientRegistryStore = useClientRegistriesStore();
</script>

<template>
  <Portal-Footer v-if="clientRegistryStore.getDobCollectionEnabled || clientRegistryStore.getCreateAccountShown">
    {{ globalStore.translations.casLoginViewDontHaveAccount }}
    <router-link
      :to="{ name: clientRegistryStore.getDobCollectionEnabled ? 'DateOfBirth' : 'CreateAccount' }"
    >
      {{ globalStore.translations.casLoginViewCreateAccount }}
    </router-link>
  </Portal-Footer>
</template>
