import { defineStore } from 'pinia';

/*
* This store has all contains data associated to consent
* To use this store import store and one of the getter function can be called as needed
* during the account creation process
* */
export default defineStore('dateOfBirthStore', {
  state: () => ({
    age: 0,
  }),
  actions: {
    setAge(age) {
      this.age = age;
    },
  },
  getters: {
    getAge: (state) => state.age,
  },
});
