<script setup>
/* Imports */
import { reactive } from 'vue';
import useGlobalStore from '../../store/globalStore';

import PortalPrimaryHeader from '../../components/PortalPrimaryHeader/PortalPrimaryHeader.vue';
import ValidationForm from '../../components/ValidationForm/ValidationForm.vue';
import useViewStore from '../../store/viewStore';
import useConsentStore from '../../store/consentStore';

import successHandler from '../../modules/successHandler';

/* Store */
const globalStore = useGlobalStore();
const viewStore = useViewStore();
const consentStore = useConsentStore();

/* State */
const state = reactive({
  consents: [],
  validation: {},
  formLoading: false,
});

/* checkbox validation */
const validateCheckbox = (event) => {
  state.validation[event.target.name] = !event.target.checked;
};

const handleSubmit = async () => {
  state.formLoading = true;
  const reconsentRes = await consentStore.setReconsent({
    consentTypeList: consentStore.getReconsentTypeList,
  });

  const {
    responseStatus,
  } = reconsentRes;

  const { type } = responseStatus;

  if (type === 'SUCCESSFUL') {
    successHandler(reconsentRes);
    return;
  }
  state.formLoading = false;
};

/* Cancel the action */
const cancelBtn = () => {
  viewStore.setCurrentView('signIn');
};
</script>
<template>
  <div class="reconsent">
    <Portal-Primary-Header
      :title="globalStore.translations.consentFormPageTitle"
    />

    <Validation-Form
      class="reconsent__form"
      :submit-form="handleSubmit"
      :submit-btn-text="globalStore.translations.reconsentButtonContinue"
      :validation="state.validation"
      :loading="state.formLoading"
    >
      <fieldset
        v-for="(reConsent, index) in consentStore.getReconsentTypeList"
        :key="reConsent"
        class="reconsent__form__input checkbox"
      >
        <g-checkbox :label="reConsent.labelText">
          <input
            :id="reConsent.consentType"
            v-model="state.consents[index]"
            :name="reConsent.consentType"
            type="checkbox"
            required
            @change="validateCheckbox($event)"
          >
        </g-checkbox>
        <p class="reconsent__form__consent-text">
          <a :href="reConsent.consentUrl">
            {{ reConsent.consentText }}
          </a>
        </p>
      </fieldset>
    </Validation-Form>

    <div class="reconsent__form__links">
      <button
        id="cancel-button"
        type="button"
        class="reconsent__form__link-btn"
        @click="cancelBtn"
      >
        {{ globalStore.translations.generalButtonCancel }}
      </button>
    </div>
  </div>
</template>

<style lang="scss">
.reconsent__form {
  &__consent-text {
    margin-left: 30px;
  }

  &__actions {
    display: flex;
    flex-direction: column;
  }

  &__links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 2em;
  }

  &__link-btn {
    background: transparent;
    width: 100%;
    border: 1px solid var(--sso-text-link-textColor);
    color: var(--sso-text-link-textColor);
    cursor: pointer;
    font-family: var(--sso-text-body-fontStack);
    font-size: var(--sso-text-body-fontSize);
    font-weight: 400;
    padding: var(--sso-button-primary-default-padding);
    text-transform: var(--sso-button-primary-default-textTransform);

    &:disabled {
      color: $color-gray-45;
      cursor: not-allowed;
    }
  }
}
</style>
