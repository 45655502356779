<script setup>
/* Imports */
import { reactive } from 'vue';
import useGlobalStore from '../../store/globalStore';
import useViewStore from '../../store/viewStore';
import useDateOfBirthStore from '../../store/dateOfBirthStore';

import PortalPrimaryHeader from '../../components/PortalPrimaryHeader/PortalPrimaryHeader.vue';
import PortalFooterSignIn from '../PortalFooterSignIn/PortalFooterSignIn.vue';
import ValidationForm from '../../components/ValidationForm/ValidationForm.vue';
import countries from '../../config/countriesExport.json';

/* Store */
const globalStore = useGlobalStore();
const viewStore = useViewStore();
const dateOfBirthStore = useDateOfBirthStore();

/* Locale */
const defaultCountrySelect = globalStore.getDefaultCountrySelect;

/* State */
const state = reactive({
  dateInput: '',
  dateError: '',
  locationSelect: defaultCountrySelect,
  validation: {},
  formLoading: false,
});

state.validation.country = false;

const validateDateOfBirth = (event) => {
  const selectedDate = new Date(event.target.value);
  const today = new Date();
  state.validation[event.target.name] = event.target.value === '' || selectedDate > today;
  state.dateError = '';
  if (event.target.value === '') {
    state.dateError = globalStore.translations.dobEntryDateOfBirthValidationReqd;
  } else if (selectedDate > today) {
    state.dateError = globalStore.translations.dobEntryDateOfBirthValidationInvalid;
  }
};

const validateDropdown = (event) => {
  state.validation[event.target.name] = event.target.value === '';
};

/* Handle submit the form */
const handleSubmit = async () => {
  state.formLoading = true;
  const dateOfBirth = new Date(state.dateInput);
  const today = new Date();
  let age = today.getFullYear() - dateOfBirth.getFullYear();
  const monthDiff = today.getMonth() - dateOfBirth.getMonth();
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dateOfBirth.getDate())) {
    age -= 1;
  }
  dateOfBirthStore.setAge(age);
  viewStore.setCurrentView('confirmAge');
  state.formLoading = false;
};

</script>

<template>
  <div class="create">
    <Portal-Primary-Header :title="globalStore.translations.createAccPageTitle" />
    <Validation-Form
      class="create-form"
      :submit-form="handleSubmit"
      :submit-btn-text="globalStore.translations.generalButtonConfirm"
      :validation="state.validation"
      :loading="state.formLoading"
      :allow-autofill="false"
    >
      <fieldset class="create-form__input">
        <div class="validation-input validation-form__input">
          <g-input
            class="create-form__input"
            :label="globalStore.translations.dobEntryDateOfBirthLabel"
            :error="state.validation['dateOfBirth']"
            label-for="dateOfBirth"
            aria-describedby="dateOfBirthHelpText"
            required
          >
            <input
              id="dateOfBirth"
              v-model="state.dateInput"
              type="date"
              name="dateOfBirth"
              class="g__input"
              required
              @change="validateDateOfBirth($event)"
            >
          </g-input>
          <g-error
            v-if="state.validation['dateOfBirth']"
            class="validation-input__error"
          >
            {{ state.dateError }}
          </g-error>
          <g-help-text
            v-if="!state.validation['dateOfBirth']"
            id="dateOfBirthHelpText"
          >
            {{ globalStore.translations.dobEntryDateOfBirthHint }}
          </g-help-text>
        </div>
      </fieldset>
      <fieldset class="create-form__input dropdown">
        <div class="validation-input validation-form__select">
          <g-drop-down
            class="create-form__select"
            :label="globalStore.translations.generalTextLocationPromptCountryOrRegion"
            aria-describedby="countryHelpText"
            required
          >
            <select
              id="country"
              v-model="state.locationSelect"
              name="country"
              autocomplete="country"
              required
              @change="validateDropdown($event)"
            >
              <option
                v-for="country in countries"
                :key="country.locale.country"
                :value="country.locale.country"
              >
                {{ country.label }}
              </option>
            </select>
          </g-drop-down>
          <g-help-text id="countryHelpText">
            {{ globalStore.translations.generalTextLocationPromptHint }}
          </g-help-text>
        </div>
      </fieldset>
    </Validation-Form>
    <Portal-Footer-Sign-In />
  </div>
</template>

<style lang="scss" scoped>
.validation-input {
  margin-bottom: 1.5em;
}
</style>
