<script setup>
/* Imports */
import { onMounted } from 'vue';
import useGlobalStore from '../../store/globalStore';
import useViewStore from '../../store/viewStore';
import useSigninStore from '../../store/signinStore';

import DateOfBirth from '../../containers/DateOfBirthForm/DateOfBirthForm.vue';
import ConfirmAge from '../../containers/ConfirmAge/ConfirmAge.vue';

/* Store */
const globalStore = useGlobalStore();
const viewStore = useViewStore();
const signInStore = useSigninStore();

const setDefaultView = () => {
  globalStore.setGlobalFlow('dateOfBirth');
  viewStore.setCurrentView('dateOfBirth');
};

onMounted(() => signInStore.checkLogin(setDefaultView));
</script>

<template>
  <Date-Of-Birth v-if="viewStore.getShowDateOfBirth" />
  <Confirm-Age v-if="viewStore.getShowConfirmAge" />
</template>
