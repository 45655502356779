<script setup>
/* Imports */
import { reactive, watch } from 'vue';
import { useRouter } from 'vue-router';
import useGlobalStore from '../../store/globalStore';
import useSigninStore from '../../store/signinStore';
import useMfaStore from '../../store/mfaStore';
import useFormValidationStore from '../../store/formValidationStore';
import useViewStore from '../../store/viewStore';

import PasswordInput from '../PasswordInput/PasswordInput.vue';
import ValidationForm from '../../components/ValidationForm/ValidationForm.vue';
import PortalPrimaryHeader from '../../components/PortalPrimaryHeader/PortalPrimaryHeader.vue';
import PortalFooterCreateAccount from '../PortalFooterCreateAccount/PortalFooterCreateAccount.vue';

import successHandler from '../../modules/successHandler';

/* Router */
const router = useRouter();

/* Store */
const globalStore = useGlobalStore();
const signInStore = useSigninStore();
const mfaStore = useMfaStore();
const formValidationStore = useFormValidationStore();
const viewStore = useViewStore();

/* State */
const state = reactive({
  newPasswordInput: '',
  confirmNewPasswordInput: '',
  signOutAllInput: false,
  validation: {},
  formLoading: false,
  triggerPasswordValidation: false,
  triggerConfirmPasswordValidation: false,
});

const getSignOutAllLabel = globalStore.translations.generalTextGlobalLogout;

/* Handle password */
const matchesPasswordValue = (value) => {
  if (state.newPasswordInput !== '') {
    state.triggerPasswordValidation = value !== state.newPasswordInput;
  }
  return value === state.newPasswordInput || globalStore.translations.generalValidationPasswordsDontMatch;
};
const matchesConfirmPasswordValue = (value) => {
  if (state.confirmNewPasswordInput !== '') {
    state.triggerConfirmPasswordValidation = value !== state.confirmNewPasswordInput;

    return value === state.confirmNewPasswordInput
      || globalStore.translations.generalValidationPasswordsDontMatch;
  }
  return true;
};
const newPasswordValidation = [formValidationStore.getIsPassword, matchesConfirmPasswordValue];
const confirmNewPasswordValidation = [formValidationStore.getIsPassword, matchesPasswordValue];

const validateInput = (name, invalid) => {
  state.validation[name] = invalid;
};

/* Handle validation form submit */
const handleSubmit = async () => {
  state.formLoading = true;
  const response = await signInStore.setNewPassword({
    newPassword: state.newPasswordInput,
    signOutAll: state.signOutAllInput,
  });

  const {
    responseStatus,
  } = response;
  const { type } = responseStatus;

  // Session Expired
  if (type === 'SESSION_EXPIRED') {
    viewStore.setCurrentView('signIn', false);

    return;
  }

  if (type === 'PHONE_VERIFICATION_REQUIRED') {
    viewStore.setCurrentView('verifyPhone');

    return;
  }

  if (type === 'PHONE_REGISTRATION_REQUIRED') {
    viewStore.setCurrentView('updatePhone');

    return;
  }

  if (mfaStore.getIsMfaFlow) {
    await router.push({ name: 'Mfa' });

    return;
  }
  // for permMfa return to signIn with the flash message
  if (type === 'PERMANENT_MFA_SETUP_REQUIRED_RESET_PASSWORD') {
    viewStore.setCurrentView('signIn', false);

    return;
  }

  if (type === 'RECONSENT_REQUIRED') {
    viewStore.setCurrentView('reConsentForm');

    return;
  }

  // Success
  if (type === 'SUCCESSFUL') {
    successHandler(response);
    return;
  }

  state.formLoading = false;
};

watch(() => [state.newPasswordInput, state.confirmNewPasswordInput], ([newPass, newConfirmPass]) => {
  state.triggerPasswordValidation = newPass === newConfirmPass;
  state.triggerConfirmPasswordValidation = newPass === newConfirmPass;
});

</script>

<template>
  <div class="change-password__form">
    <Portal-Primary-Header
      :title="globalStore.translations.generalHeaderChangePassword"
    />
    <g-copy type="large">
      {{ globalStore.translations.casLoginViewChangePasswordDesc }}
    </g-copy>
    <Validation-Form
      :submit-form="handleSubmit"
      :submit-btn-text="globalStore.translations.generalButtonSave"
      :validation="state.validation"
      :loading="state.formLoading"
    >
      <fieldset>
        <Password-Input
          id="new-password"
          v-model="state.newPasswordInput"
          :validation-rules="newPasswordValidation"
          required
          :label-text="globalStore.translations.casLoginViewNewPassword"
          name="new-password"
          :trigger-validation="state.triggerPasswordValidation"
          @validate-input="validateInput"
        />
      </fieldset>
      <fieldset class="change-password__input confirm">
        <Password-Input
          id="confirm-new-password"
          v-model="state.confirmNewPasswordInput"
          :validation-rules="confirmNewPasswordValidation"
          required
          :label-text="globalStore.translations.casLoginViewRetypeNewPassword"
          name="confirm-new-password"
          :trigger-validation="state.triggerConfirmPasswordValidation"
          @validate-input="validateInput"
        />
      </fieldset>
      <fieldset class="change-password__input checkbox">
        <g-checkbox :label="getSignOutAllLabel">
          <input
            id="sign-out-all"
            v-model="state.signOutAllInput"
            name="signOutAll"
            type="checkbox"
          >
        </g-checkbox>
      </fieldset>
    </Validation-Form>
    <Portal-Footer-Create-Account />
  </div>
</template>

<style lang="scss">
.change-password {
  &__input {
    &.checkbox {
      margin-bottom: 3em;
    }

    &.confirm {
      .validation-form__input {
        margin-bottom: 0.5em;
      }
    }
  }
}
</style>
