<script setup>
/* Imports */
import { computed } from 'vue';
import useVisualOptionsStore from '../../store/visualOptionsStore';

const visualOptionsStore = useVisualOptionsStore();

/* Props */
const props = defineProps({
  title: {
    type: String,
    default: '',
  },
});

const isHeaderWithCountryNameInTitle = computed(() => props.title.includes('-'));
</script>

<template>
  <div
    v-if="!visualOptionsStore.getHidePrimaryHeader"
    class="portal-primary-header"
  >
    <g-heading
      :content="props.title"
      text-align="left"
      heading-size="1"
      theme="light"
      :class="{'heading-with-country-name': isHeaderWithCountryNameInTitle}"
    />
  </div>
</template>

<style lang="scss">
.portal-primary-header {
  border-bottom: 2px solid $color-white-header;
  text-transform: var(--sso-text-h1-textTransform);
  padding-bottom: 0.5em;
  margin-bottom: 0.8em;

  h1 {
    font-size: var(--sso-text-h1-fontSize);
  }

  .heading-with-country-name h1 {
    /* stylelint-disable-next-line declaration-no-important */
    font-size: 1rem !important;

    @include breakpoint('sm') {
      /* stylelint-disable-next-line declaration-no-important */
      font-size: 1.5rem !important;
    }
  }
}
</style>
