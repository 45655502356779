<script setup>
/* Imports */
import { computed, reactive } from 'vue';
import useGlobalStore from '../../store/globalStore';
import useClientRegistriesStore from '../../store/clientRegistriesStore';
import useVisualOptionsStore from '../../store/visualOptionsStore';
import FlashMessages from '../FlashMessages/FlashMessages.vue';
import NavHeader from '../../components/NavHeader/NavHeader.vue';
import PortalLogo from '../../components/PortalLogo/PortalLogo.vue';
import PortalLoader from '../../components/PortalLoader/PortalLoader.vue';
import PortalFooterLabels from '../PortalFooterLabels/PortalFooterLabels.vue';

/* Store */
const globalStore = useGlobalStore();
const clientRegistriesStore = useClientRegistriesStore();
const visualOptionsStore = useVisualOptionsStore();

/* Props */
const props = defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
});

const state = reactive({
  showFormHeaderGarminLogo: computed(
    () => visualOptionsStore.getVisualOptions.global.displayFormHeaderGarminLogo,
  ),
  customServiceLogoUrl: computed(
    () => visualOptionsStore.getVisualOptions.global.header.logoUrl,
  ),
});
</script>

<template>
  <main
    id="portal"
    role="main"
    class="portal__main"
  >
    <Nav-Header
      v-if="!visualOptionsStore.getHidePageHeader"
      :logo-title="clientRegistriesStore.getClientId"
      :service="globalStore.getService"
      :custom-service-logo-url="state.customServiceLogoUrl"
    />
    <Flash-Messages />
    <div :class="clientRegistriesStore.getThickClient ? 'portal__container__mobile' : 'portal__container'">
      <div :class="clientRegistriesStore.getThickClient ? 'portal__card__mobile' : 'portal__card'">
        <Portal-Logo
          v-if="state.showFormHeaderGarminLogo"
        />
        <div class="portal__wrapper">
          <Portal-Loader
            v-show="props.loading"
            class="portal__loader"
          />
          <div
            v-show="!props.loading"
            class="portal__content"
          >
            <slot />
          </div>
        </div>
      </div>
    </div>
    <Portal-Footer-Labels v-if="!visualOptionsStore.getHidePageFooter" />
  </main>
</template>

<style lang="scss">
.portal__main {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.portal {
  &__container {
    display: flex;
    justify-self: center;
    justify-content: center;
    align-items: baseline;
    flex-grow: 1;
    padding: 6rem 1rem;
    margin: 0;
  }

  &__container__mobile {
    display: flex;
    justify-self: center;
    justify-content: center;
    align-items: baseline;
    flex-grow: 1;
    padding: 6rem 1rem;
    margin: 0;
    background-color: $color-transparent;
  }

  &__card {
    display: flex;
    flex-direction: column;
    max-width: 20.875rem;
    width: 100%;
    box-shadow: 0 2px 4px rgb(0 0 0 / 25%);
    padding: 1rem 2rem 2rem;
    background-color: $color-white;

    @include breakpoint('sm') {
      padding: 2rem 3rem 3rem;
    }
  }

  &__card__mobile {
    display: flex;
    flex-direction: column;
    width: 96%;
    padding: 1rem 2rem 2rem;
    background-color: $color-transparent;

    @include breakpoint('sm') {
      padding: 2rem 3rem 3rem;
    }
  }

  &__wrapper {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }

  &__loader {
    width: 8em;
    margin: 9em auto;
  }

  &__content {
    width: 100%;
  }
}
</style>
